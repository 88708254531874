<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p> 我是一个民营企业的老板，因为身边的朋友都在办理海外的身份所以我也开始关心这个事情。   </p>
        <p>  最初是在2013年春天，我开始关注移民，但当时还没有明确的方向，也不知道移民是为了什么。  </p>
        <p>  经过长时间的咨询和考虑，基于小孩将来的教育和我们自己的养老问题，综合我们家本身就是做外贸出身，自己会说一点英语，我决定为全家办理美国移民。这个时候已经过去了一年。  </p>
        <p>   在这一年里，我和老婆了解了相关的一些政策信息，与多家移民中介机构沟通，相比较之后我们选择了跃迁。我认为跃迁的咨询顾问和文档经理非常专业认真，他们对于我的“刁难“都能给与一一回应，并且能够非常细致介绍办理流程，为我们答疑解惑。 </p>
        <p>   了解到我们正巧卖出了房产，而卖房款的金额正好和移民所需的金额差不多。跃迁的顾问依照我们的实际情况，为我们选择了以卖房款作为投资来源这个方案。整个过程非常顺利，十分感谢跃迁的帮助！
        </p>
        <p>
          <img loading="lazy" class="aligncenter" src="@/assets/Cases/6/1.jpg">
        </p>
      </div>


      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases8",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>